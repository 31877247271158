<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel">
      <form-observer @save="save" @cancel="cancel" :loading="isLoading">
        <settings v-model="obModel" />
      </form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Mixins, Component, PropSync } from "vue-property-decorator";
import CfecodesMixin from "@/modules/cfecodes/mixins/CfecodesMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import Settings from "@/modules/cfecodes/components/tabs/Settings.vue";

@Component({
  components: {
    SheetDrawer,
    Settings,
  },
})
export default class CfecodesForm extends Mixins(CfecodesMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
}
</script>
