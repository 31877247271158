<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obCfeCode.form" label="form" required />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obCfeCode.line" label="line" required />
    </v-col>

    <v-col cols="12">
      <v-textarea
        :label="$t('description')"
        outlined
        v-model="obCfeCode.description"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { CfeCode } from "@planetadeleste/vue-mc-gw";

@Component
export default class CfecodesSettings extends Vue {
  @VModel({ type: Object, default: () => new CfeCode() })
  obCfeCode!: CfeCode;
}
</script>
